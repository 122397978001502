import MENU from "constants/menu";
import React from "react";

const Navbar = () => {
  return (
    <div className="bg-blue-500 text-white">
      <div className="container h-36 mx-auto flex justify-between items-center px-10 pt-16">
        <a className="text-5xl font-bold">.A.I</a>
        <div className="flex items-center">
          {MENU.map((item, index) => (
            <a
              key={index}
              href={item.link}
              className="mx-8 cursor-pointer hover:text-main-blue hover:bg-white hover:rounded-full px-4 py-2"
            >
              {item.title}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
