import React from "react";
import HeroImg from "assets/img/background.jpeg";

const Home = () => {
  return (
    <div className="bg-blue-500 shadow-[0_0_-60px_0_rgba(250,0,0,0.7)]">
      <div className="container mx-auto">
        <div className="relative">
          <img src={HeroImg} className="w-full rounded-t-3xl" />
          <div className="absolute top-32 left-8 max-w-sm">
            <div className="text-white text-7xl font-bold">AI Innovation</div>
            <div className="py-8 flex justify-start gap-2">
              <div className="text-white text-md bg-indigo-300 rounded-full p-3 hover:bg-white cursor-pointer hover:text-blue-800">
                Contrarylorem
              </div>
              <div className="text-white text-md bg-blue-700 rounded-full p-3 hover:bg-white cursor-pointer hover:text-blue-800">
                Popularconsectetur
              </div>
            </div>
            <div className="mr-20 text-white text-sm">
              Website in development Join our discord @ https://discord.gg/NmaZGsqX or email contact@draconian.ai
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
