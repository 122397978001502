import React from "react";
import RoundImage from "assets/img/round.jpg";
const About = () => {
  return (
    <div className="container mx-auto mt-10 mb-20 px-20">
      <div className=" shadow-xl rounded-b-xl">
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-3 flex flex-col gap-5 px-10 items-center">
            <div className="text-4xl font-bold">Services</div>
            <img
              src={RoundImage}
              className="flex-none w-28 h-28 rounded-full"
            />
            <div className="text-sm text-center">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been
            </div>
            <div className="bg-black text-white rounded-full text-lg p-2 hover:bg-gray-300 cursor-pointer hover:text-blue-800">
              Learn more
            </div>
          </div>
          <div className="col-span-6 flex flex-col gap-5 px-10 items-center border-l border-r border-gray-300">
            <div className="text-4xl font-bold">About Us</div>
            <div className="flex gap-20 justify-between">
              <img
                src={RoundImage}
                className="flex-none w-28 h-28 rounded-full"
              />
              <img
                src={RoundImage}
                className="flex-none w-28 h-28 rounded-full"
              />
            </div>
            <div className="text-sm text-center">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </div>
          </div>
          <div className="col-span-3 flex flex-col gap-5 px-10 items-center">
            <div className="text-4xl font-bold">Get Us</div>
            <img
              src={RoundImage}
              className="flex-none w-28 h-28 rounded-full"
            />
            <div className="text-sm text-center">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been
            </div>
            <div className="bg-black text-white rounded-full text-lg p-2 hover:bg-gray-300 cursor-pointer hover:text-blue-800">
              Learn more
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
