import Navbar from "components/layouts/Navbar";
import React from "react";
import Home from "./Home";
import About from "components/layouts/About";

const Dashboard = () => {
  return (
    <div>
      <Navbar />
      <Home />
      <About />
    </div>
  );
};

export default Dashboard;
